document.addEventListener("turbolinks:load", () => {
  const newSymptomForm = document.getElementById("new_symptom");

  if (newSymptomForm) {
    const timeZoneField = newSymptomForm.querySelector(
      "input#symptom_timezone"
    );
    const timeZoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timeZoneField.setAttribute("value", timeZoneValue);
  }
});
