class Login {
  constructor(el) {
    this.togglePassword = el.querySelector(".login__toggle-password");
    this.passwordField = el.querySelector("#password");
    this.showPassword = false;

    this.togglePassword.addEventListener("click", () => {
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        this.passwordField.setAttribute("type", "text");
        this.togglePassword.innerHTML = "Hide Password";
      } else {
        this.passwordField.setAttribute("type", "password");
        this.togglePassword.innerHTML = "Show Password";
      }
    });
  }
}

document.addEventListener("turbolinks:load", () => {
  const login = document.getElementById("login");

  if (login) {
    new Login(login);
  }
});
