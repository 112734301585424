//------------------------------------------------------------------------
// Modal windows
//------------------------------------------------------------------------
import Modal from "../lib/modal";
import scroll from "@threespot/freeze-scroll";

document.addEventListener("turbolinks:load", () => {
  const el = document.querySelector(".modal");

  if (el) {
    const modal = new Modal(el);

    document.addEventListener("turbolinks:before-visit", () => {
      scroll.unfreeze();
      modal.destroy();
    });
  }
});
