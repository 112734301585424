import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", () => {
  const dateInput = document.querySelector(".flatpickr-date");

  if (dateInput) {
    flatpickr(dateInput, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      position: "auto center",
      maxDate: "today",
      allowInput: true,
    });
  }
});
