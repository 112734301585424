//------------------------------------------------------------------------
// Modal windows
//------------------------------------------------------------------------
import Modal from "../lib/modal";
import scroll from "@threespot/freeze-scroll";

document.addEventListener("turbolinks:load", () => {
  const nav = document.getElementById("nav");

  if (nav) {
    const navModal = new Modal(nav);

    document.addEventListener("turbolinks:before-visit", () => {
      scroll.unfreeze();
      navModal.destroy();
    });
  }
});
