import Timer from "easytimer.js";
class SymptomTimer {
  constructor(el) {
    this.running = false;
    this.timer = new Timer();

    this.el = el;
    this.icon = el.querySelector(".timer__icon");
    this.time = el.querySelector("[data-timer-time]");
    this.timeLabels = el.querySelector(".timer__time__labels");
    this.timerStart = el.querySelector("[data-timer-start]");
    this.timerStop = el.querySelector("[data-timer-stop]");
    this.timerToggleText = el.querySelector("[data-timer-toggle-text]");
    this.form = el.querySelector("[data-symptom-form]");
    this.startTimeEl = el.querySelector('input[id="symptom_started"]');
    this.minutesEl = el.querySelector('input[id="symptom_minutes"]');
    this.secondsEl = el.querySelector('input[id="symptom_seconds"]');
    this.timeFieldsEl = el.querySelector('.timer__timefields');
    this.dateEl = el.querySelector('input[id="symptom_date"]');
    this.recordPast = el.querySelector("[data-record-past]");
    this.submitButton = el.querySelector(".timer__submit");
    this.stopButton = el.querySelector(".timer__stop");
    this.form = el.querySelector(".timer__form");

    this.timerStart.addEventListener("click", () => {
      this.running = !this.running;

      this.timer.start({
        precision: "seconds",
      });
      this.el.setAttribute("data-active", true);
      this.cycle();

      this.icon.setAttribute("style", "display: none;");
      this.timerStart.setAttribute("style", "display: none;");
      this.recordPast.setAttribute("style", "display: none;");

      this.form.classList.remove("-hidden");

      this.date = new Date().toString();
      this.startTime = new Date().toLocaleString("en-US", {
        timeStyle: "short",
      });
    });

    this.timerStop.addEventListener("click", () => {
      this.running = false;
      this.time.remove();
      this.timeLabels.remove();
      this.dateEl.value = this.date;
      this.startTimeEl.value = this.startTime;
      this.minutesEl.classList.remove("-hidden");
      this.secondsEl.classList.remove("-hidden");
      this.timeFieldsEl.classList.remove("-hidden");
      this.stopButton.remove();
      this.submitButton.classList.remove("-hidden");
      this.minutesEl.value = this.timer.getTimeValues().minutes;
      this.secondsEl.value = this.timer.getTimeValues().seconds;
    });

    // this.timerToggle.addEventListener("click", () => {
    //   this.running = !this.running;

    //   if (this.running) {
    //     this.timer.start({
    //       precision: "seconds",
    //     });
    //     this.el.setAttribute("data-active", true);
    //     this.timerToggleText.innerHTML = "Stop Timer";
    //     this.cycle();
    //     this.recordPast.setAttribute("style", "visibility: hidden;");
    //     date = new Date().toString();
    //     startTime = new Date().toLocaleString("en-US", {
    //       timeStyle: "short",
    //     });
    //   } else {
    //     this.date.setAttribute("value", date);
    //     this.startTimeEl.setAttribute("value", startTime);
    //     this.minutesEl.setAttribute(
    //       "value",
    //       this.timer.getTimeValues().minutes
    //     );
    //     this.secondsEl.setAttribute(
    //       "value",
    //       this.timer.getTimeValues().seconds
    //     );

    //     this.form.submit();
    //   }
    // });
  }

  cycle() {
    if (this.running) {
      this.time.innerHTML =
        this.timer.getTimeValues().minutes.toString().padStart(2, "0") +
        ":" +
        this.timer.getTimeValues().seconds.toString().padStart(2, "0");

      setTimeout(() => {
        this.cycle();
      }, 200);
    }
  }
}

document.addEventListener("turbolinks:load", () => {
  const timer = document.getElementById("timer");

  if (timer) {
    new SymptomTimer(timer);
  }
});
