import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", () => {
  const timeInput = document.querySelectorAll(".flatpickr-time");

  timeInput.forEach(input => {
    flatpickr(input, {
      altInput: true,
      allowInput: true,
      noCalendar: true,
      enableTime: true,
      dateFormat: "h:i K",
      minuteIncrement: 1,
    });
  });
});
