import InfinteScroll from "infinite-scroll";

document.addEventListener("turbolinks:load", () => {
  const infiiteScroll = document.querySelector(".symptoms__rows");

  if (infiiteScroll) {
    new InfinteScroll(infiiteScroll, {
      path: 'a[rel="next"]',
      append: ".symptoms__row",
      history: false,
    });
  }
});
